import React, {Component} from 'react';
import '../../../styles/static-web-css/bootstrap.css';
import '../../../styles/static-web-css/style.css';
import luluhk from '../../../static-web-img/luluhk.png';
import {changeLanguage} from "../../../utils/utils";

class TermsWebEn extends Component {
    
    componentDidMount () {
        const delay = ms => new Promise(res => setTimeout(res, ms));

        const script = document.createElement("script");
        script.src = "https://ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js";
        script.async = true;
        document.body.appendChild(script);

        const loadJqueryAfter = async () => {
            await delay(1000);
            const script2 = document.createElement("script");
    
            script2.src = "https://maxcdn.bootstrapcdn.com/bootstrap/4.4.1/js/bootstrap.min.js";
            script2.async = true;
        
            document.body.appendChild(script2);
    
            const script3 = document.createElement("script");
        
            script3.src = "https://use.fontawesome.com/releases/v5.13.0/js/all.js";
            script3.async = true;
        
            document.body.appendChild(script3);
        };
        
        loadJqueryAfter();
    }

    render() {
        return (
            <div className="static-web-styling">
        <nav className="static-web-styling navbar navbar-expand-lg navbar-light border-bottom border-dark fixed-top" id="topnav">
            <a className="static-web-styling navbar-brand" href="/en"><img draggable="false" src={luluhk} alt="luluhk"/></a>

            <button className="static-web-styling navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar-collapse-main">
                <span className="static-web-styling sr-only">Toggle navigation</span>
                <span className="static-web-styling navbar-toggler-icon"></span>
            </button>

            <div className="static-web-styling collapse navbar-collapse" id="navbar-collapse-main">
                <ul className="static-web-styling navbar-nav ml-auto">
                    <li className="static-web-styling nav-item"><a className="static-web-styling nav-link" href="/en/privacy">Privacy</a></li>
                    <li className="static-web-styling nav-item"><a className="static-web-styling nav-link active" href="/en/terms">Terms</a></li>
                </ul>
            </div>
        </nav>
        <div className="static-web-styling container">
            <div className="static-web-styling terms text-justify">
                <h1>Terms and Conditions</h1>
                <p>Version: 1.0 | Effective Date: 1-November-2024</p>
                <hr/>
                <p>Lulu HK Technologies Limited (中文：路路香港科技有限公司) and/or its subsidiary(ies) and/or affiliates (hereinafter collectively referred to as “Lulu HK”) operates and provides services via luluhk.com, its sub-domains, merchant service system, and any other websites, their sub-domains, media platforms, or applications including the Lulu HK mobile applications, developed in whole or in part by Lulu HK (hereinafter singly or collectively referred to as the “Channels”).</p>
                <p>Access to and use of our Channels shall be subject to these <strong>Terms and Conditions</strong> and our <strong>Privacy Policy</strong>. By using our Channels and services or creating a Lulu HK account, user(s) of the Channels (whether registered or not) (“User(s)”) acknowledges that he/she/it has read and understood our <strong>Terms and Conditions</strong> and <strong>Privacy Policy</strong> and agrees to be bound by them. If a User does not accept either or both our <strong>Terms and Conditions</strong> and/or <strong>Privacy Policy</strong>, please do not join, access or otherwise use any services offered by us via the Channels.</p>
                <p>We reserve the right to update, revise, modify or amend our <strong>Terms and Conditions</strong> and <strong>Privacy Policy</strong> at any time as we deem necessary without prior notice. We strongly recommend Users to periodically review our latest <strong>Terms and Conditions</strong> and <strong>Privacy Policy</strong>. The revised <strong>Terms and Conditions</strong> and <strong>Privacy Policy</strong> will become effective immediately upon posting of the revised version on their corresponding webpage. We will terminate User’s access to our Channels and services upon his/her/its notice to us that any change is unacceptable, otherwise User’s continued use of Lulu HK’s Channels and services following such changes constitutes acceptance of the changes.</p>
                
                <ol className="static-web-styling ol-h2">
                    <li className="static-web-styling ol-h2-li"><h2>Definitions</h2>
                        <p>In these <strong>Terms and Conditions</strong>, unless the context otherwise requires:</p>
                        <dl>
                            <div>
                                <dt>Agreement</dt>
                                <dd>refers to these <strong>Terms and Conditions</strong>, <strong>Privacy Policy</strong>, and the terms and conditions of any Service Order or free trial offer;</dd>
                            </div>
                            <div>
                                <dt>Authorized User(s)</dt>
                                <dd>refers to the user(s) authorized by a Merchant to use the Merchant System;</dd>
                            </div>
                            <div>
                                <dt>Channels</dt>
                                <dd>singly or collectively refers to luluhk.com, its sub-domains, merchant service system, and any other websites, their sub-domains, media platforms, or applications including the Lulu HK mobile applications, developed in whole or in part by Lulu HK;</dd>
                            </div>
                            <div>
                                <dt>HKIAC</dt>
                                <dd>means the Hong Kong International Arbitration Centre;</dd>
                            </div>
                            <div>
                                <dt>Hong Kong</dt>
                                <dd>means the Hong Kong Special Administrative Region;</dd>
                            </div>
                            <div>
                                <dt>Lulu HK</dt>
                                <dd>collectively refers to Lulu HK Technologies Limited (中文：路路香港科技有限公司) and/or its subsidiary(ies) and/or affiliates;</dd>
                            </div>
                            <div>
                                <dt>Material(s)</dt>
                                <dd>refers to the content, merchant information, shop information, product information, photos, text, logos, images, graphics, links and/or any information and material on the Channels;</dd>
                            </div>
                            <div>                         
                                <dt>Member(s)</dt>	
                                <dd>refers to the individual(s) or entity(ies) with a registered account on our Channels;</dd>
                            </div>
                            <div>                         
                                <dt>Merchant(s)</dt>	
                                <dd>refers to any organization and its Related Parties using our Merchant Services;</dd>
                            </div>
                            <div>                         
                                <dt>Merchant Services</dt>	
                                <dd>refers to our Merchant System and any other merchant services provided to a Merchant by us;</dd>
                            </div>
                            <div>                         
                                <dt>Merchant System</dt>	
                                <dd>refers to the merchant service system developed by us;</dd>
                            </div>
                            <div>                         
                                <dt>Participating Branch(es)/Outlet(s)</dt>	
                                <dd>refers to the branch(es) or outlet(s) nominated by a merchant to use our Merchant Services;</dd>
                            </div>
                            <div>                         
                                <dt>Personal Data</dt>	
                                <dd>refers to personal information and data;</dd>
                            </div>
                            <div>                         
                                <dt>Product(s)</dt>	
                                <dd>means product(s) and service(s);</dd>
                            </div>
                            <div>                         
                                <dt>Related Parties</dt>	
                                <dd>refers to the Participating Branch(es)/Outlet(s), employees, and any other Authorized Users of a merchant;</dd>
                            </div>
                            <div>                         
                                <dt>Service Order</dt>	
                                <dd>refers to any contract, quotation, registration form or service order form signed with us by a party to enjoy certain services provided by Lulu HK;</dd>
                            </div>
                            <div>                         
                                <dt>Uploader(s)</dt>	
                                <dd>refers to the User(s) uploading or posting any Materials on our Channels;</dd>
                            </div>
                            <div>                         
                                <dt>User(s)</dt>	
                                <dd>refers to the individual(s) or entity(ies) using our Channels, including non-registered user(s), registered user(s) and Merchant(s); and</dd>
                            </div>
                            <div>                         
                                <dt>Viewer(s)</dt>	
                                <dd>refers to the User(s) viewing and/or using the Materials posted on our Channels.</dd>
                            </div>
                        </dl>
                    </li>
                    
                    <li className="static-web-styling ol-h2-li"><h2>General Terms</h2>
                        <ol className="static-web-styling ol-li2">
                            <li className="static-web-styling ol-li2-li">We offer one or more of the following services through our Channels pursuant to the <strong>Agreement</strong>: (a) as a platform for User to browse and share the Merchants; (b) as a platform for User to browse and share the Participating Branches/Outlets uploaded by the Merchants; (c) as a platform for User to browse and share the Products uploaded by the Merchants; (d) provision of the Merchant System for the Merchant; and (e) any other features, services and/or applications made available via the Channels;</li>
                            <li className="static-web-styling ol-li2-li">We reserve the rights, at our sole discretion, to modify any of our services, including but not limited to adding, modifying, or removing any part of our Channels, and modifying or removing any Materials posted on the Channels, for any reason at any time without notice;</li>
                            <li className="static-web-styling ol-li2-li">We may invest in third-party marketing campaigns, including but not limited to Google Ads, Bing Ads, Facebook Ads, Instagram Ads, and other advertising networks, to further promote the Merchants. User acknowledge and agree that such promotion may be outside the domain of Lulu HK;</li>
                            <li className="static-web-styling ol-li2-li">Unless otherwise expressly specified, Lulu HK is not a party to any actual transaction between Users;</li>
                            <li className="static-web-styling ol-li2-li">We may, at our own discretion, carry out scheduled and non-scheduled maintenance, and data backup of our Channels, from time to time as we deem necessary;</li>
                            <li className="static-web-styling ol-li2-li">In case of any free trial offered to any User to use any module of our Channels without executing any Service Order, the trial period shall be determined by us at our sole discretion. Access to and use of the Channels by such Users shall be subject to these <strong>Terms and Conditions</strong>, <strong>Privacy Policy</strong>, and the terms specified in the free trial offer (if any); and</li>
                            <li className="static-web-styling ol-li2-li">For our policies regarding the usage and protection of Personal Data provided by Users of the Channels, please refer to our <strong>Privacy Policy</strong>.</li>
                        </ol><br/>
                    </li>

                    <li className="static-web-styling ol-h2-li"><h2>Member Registration</h2>
                        <ol className="static-web-styling ol-li2">
                            <li className="static-web-styling ol-li2-li">Users can freely browse our Channels for Materials which are made available to all Users without the need to register as a Member. However, certain Materials and functions of our Channels are only available to Members;</li>
                            <li className="static-web-styling ol-li2-li">Users warrant that the information they provide for Member registration are true, accurate, complete, legal and not misleading in all material respects, and they shall promptly update the information when there is any subsequent change in such information;</li>
                            <li className="static-web-styling ol-li2-li">Users shall not select a username that infringes any party’s trademark or other proprietary rights, or that is deemed offensive or inappropriate by us. We reserve the right to request any User to change his/her/its username upon our notice to him/her/it. If any User refuses to abide by such request, we shall have the right to suspend or terminate that User’s account without prior notification to that User. In any event, we shall not be liable in any way for any improper use of a username by any User;</li>
                            <li className="static-web-styling ol-li2-li">Members shall be solely and entirely responsible for maintaining the confidentiality of the username and password and any other information of their accounts. Members shall promptly notify us of any unauthorized access or use of their accounts. We shall not be liable for any loss or damage in relation to any unauthorized use of their accounts;</li>
                            <li className="static-web-styling ol-li2-li">A User’s registration as a Member may be refused by us at our sole and absolute discretion. Users agree that we are not obligated to send Users any notice or explanation regarding our rejection of their Member registration; and</li>
                            <li className="static-web-styling ol-li2-li">We reserve the rights to suspend or terminate any User’s account at any time as we deem necessary (Please refer to Section 15 headed “<strong>Termination of Account and Removal of Materials</strong>” for details).</li>
                        </ol><br/> 
                    </li>

                    <li className="static-web-styling ol-h2-li"><h2>Prohibited Uses</h2>
                        <p>All Users of our Channels agree that:</p>
                        <ol className="static-web-styling ol-li2">
                            <li className="static-web-styling ol-li2-li">Users shall not use our Channels if they do not have legal capacity to form legally binding contracts or are prohibited by law from accessing or using our services;</li>
                            <li className="static-web-styling ol-li2-li">Users shall not use our Channels in any manner that infringes any party’s intellectual property rights or other proprietary rights;</li>
                            <li className="static-web-styling ol-li2-li">Users shall not upload, post, transmit, share or store any Material in any manner that (a) violates any applicable laws or regulations; (b) violates or infringes copyright, trademark, trade secret, patent, privacy, publicity, intellectual property rights or other proprietary rights of others; (c) is unlawful, inaccurate, harmful, threatening, abusive, harassing, tortious, defamatory, vulgar, obscene, pornographic, misleading, libelous, hateful, or racially, ethnically or otherwise offensive or objectionable; (d) is for commercial purposes other than pursuant to the <strong>Agreement</strong>; or (e) contains viruses, trojan horses or other materials that may interrupt, damage or limit the functionality and operation of our Channels and services, or of any computer software, hardware or telecommunication equipment;</li>
                            <li className="static-web-styling ol-li2-li">Users shall not remove or revise any Material posted by any other Users;</li>
                            <li className="static-web-styling ol-li2-li">Users shall not alter, reproduce, adapt, distribute, publish, display, translate, reverse engineer, disassemble, decompile, or otherwise attempt to create any source code that is derived from our Channels;</li>
                            <li className="static-web-styling ol-li2-li">Users are prohibited from violating or attempting to violate the security of our Channels, including but not limited to accessing data not intended for them, logging into a server or account which they are not authorized to access, attempting to probe, scan or test the vulnerability of a system or network, attempting to breach security or authentication measures without proper authorization, attempting to interfere with service to any User, host or network, or sending unsolicited emails, messages, postings, or any other form of spamming. Violation of system or network security may result in civil and/or criminal liabilities; and</li>
                            <li className="static-web-styling ol-li2-li">Users shall not access, download, copy or use the Personal Data of any other User without his/her/its consent.</li>
                        </ol><br/>
                    </li>

                    <li className="static-web-styling ol-h2-li"><h2>Acceptable Uses</h2>
                        <ol className="static-web-styling ol-li2">
                            <li className="static-web-styling ol-li2-li"><u>Specific Uses – Uploader(s)</u>
                                <ol className="static-web-styling ol-li3">
                                    <li className="static-web-styling ol-li3-li">Uploader agrees that he/she/it shall only use our Channels for lawful purposes and for enjoying the services provided in the Channels;</li>
                                    <li className="static-web-styling ol-li3-li">Uploaders shall be solely responsible for the Materials uploaded or posted on the Channels by them;</li>
                                    <li className="static-web-styling ol-li3-li">Uploaders acknowledge and agree that, by uploading or posting Materials on our Channels, the Materials will be made public and may be shared to third-party websites/media platforms/applications by other User of our Channels;</li>
                                    <li className="static-web-styling ol-li3-li">Uploaders acknowledge and agree that they shall not be compensated for posting any Materials;</li>
                                    <li className="static-web-styling ol-li3-li">We reserve the right to determine the period of time that the Materials will be posted on our Channels, the size, placement and layout of the Materials;</li>
                                    <li className="static-web-styling ol-li3-li">We reserve the right to edit, share, and remove any Material posted on our Channels, at any time as we deem appropriate;</li>
                                    <li className="static-web-styling ol-li3-li">We shall have the right to suspend or terminate any service to any Uploader at our sole discretion; and</li>
                                    <li className="static-web-styling ol-li3-li">After Materials are posted by an Uploader, if the Materials are later removed by him/her/it or us, or his/her/its account are terminated, such Materials will no longer be accessible by the Uploader. However, such removed Materials may still appear on any part of our Channels and/or be used in any other form by us.</li>
                                </ol><br/>
                            </li>
                            <li className="static-web-styling ol-li2-li"><u>Specific Uses – Viewer(s)</u>
                                <ol className="static-web-styling ol-li3">
                                    <li className="static-web-styling ol-li3-li">Viewer agrees that he/she/it shall only use our Channels for lawful purposes and for enjoying the services provided in the Channels;</li>
                                    <li className="static-web-styling ol-li3-li">Viewer agrees that any Personal Data received via our Channels shall only be used for the purpose of identifying or locating Materials, or for the purpose of enjoying the services on our Channels. Any Personal Data received which are irrelevant to the above purposes shall be disregarded and shall not be stored, used, processed, shared, disclosed or transmitted in any way. Viewers agree that any Personal Data received shall be promptly and properly removed after fulfilling the above purposes; and</li>
                                    <li className="static-web-styling ol-li3-li">We shall not be responsible for any Viewers, in breach of our <strong>Agreement</strong>, using any other User’s Personal Data or Materials for any purpose, whether in Hong Kong or elsewhere. All Users acknowledge that all Personal Data or Materials voluntarily provided by them publicly on our Channels are given entirely at their own risk.</li>
                                </ol><br/>
                            </li>
                            <li className="static-web-styling ol-li2-li"><u>Specific Uses – Merchant(s)</u>
                                <ol className="static-web-styling ol-li3">
                                    <li className="static-web-styling ol-li3-li">Merchant agrees that it shall only use our Channels for lawful purposes and for enjoying the Merchant Services provided in the Channels;</li>
                                    <li className="static-web-styling ol-li3-li">Upon payment of the service fee to Lulu HK or upon acceptance of any free offer, Merchants will be entitled to use our Channels to upload or post information about their shops and Products, and/or other commercial materials, subject to the <strong>Agreement</strong>;</li>
                                    <li className="static-web-styling ol-li3-li">The rights of the Merchant to use our Merchant Services, which is revocable, non-exclusive, non-sublicensable, non-transferable and worldwide, are effective for the service period stipulated in the <strong>Agreement</strong> until expiry or termination, whichever is earlier. The service period will be renewed upon Merchant’s auto or manual payment of the service fee for the next period;</li>
                                    <li className="static-web-styling ol-li3-li">We reserve the right to change our service fee or institute new charges, from time to time, as we deem appropriate;</li>
                                    <li className="static-web-styling ol-li3-li">We may offer additional system features to different Merchants. The use of additional features may require the relevant Merchant to agree to additional terms and conditions specific to those additional features which may be incorporated into the <strong>Agreement</strong>;</li>
                                    <li className="static-web-styling ol-li3-li">Merchant represents and warrants that (a) it is an independent legal entity duly organized, validly existing and in good standing under the laws of the jurisdiction of its incorporation; (b) it and each of its Participating Branch(es)/Outlet(s) are properly registered and licensed to provide the Products posted on our Channels, and comply with all the applicable laws and regulations in the jurisdictions where they operate; (c) it has full corporate power, authority and legal right to enter into, execute and perform the <strong>Agreement</strong> and the obligations and transactions contemplated hereunder; (d) its entry into and performance of the <strong>Agreement</strong> do not violate any applicable laws; (e) no legal action, suit, proceeding, investigation or litigation of any nature currently is pending or, to the best of its knowledge, threatened against it, which either in any one instance or in the aggregate, may have a material and adverse effect on its ability to perform its obligations under the <strong>Agreement</strong>. It shall promptly inform us when it becomes aware that any of such representations or warranties is/will be inaccurate;</li>
                                    <li className="static-web-styling ol-li3-li">Merchant shall fully comply with the Trade Descriptions Ordinance, Chapter 362 of the Laws of the Hong Kong and/or other applicable laws in the jurisdiction where the Participating Branch/Outlet operates;</li>
                                    <li className="static-web-styling ol-li3-li">Merchant guarantees that the price (per-child-product price, per-option price and total price) of the Products and promo posted in our Channels shall be:<ul className="static-web-styling ul-li4"><li className="static-web-styling ul-li4-li">a special price/promo for our Channels; or</li><li className="static-web-styling ul-li4-li">the price/promo listed in its Participating Branch/Outlet; or</li><li className="static-web-styling ul-li4-li">the price/promo listed in other offline/online official/third-party channels;</li></ul>whichever price is the lowest and whichever promo is the most favourable to customers;</li>
                                    <li className="static-web-styling ol-li3-li">Merchant ensures that any Material about the Merchant provided through the Merchant System, including but not limited to the price and stock quantity or availability of the Products, promo, name, address, status (active, under renovation, temporarily closed, permanently closed, or relocated), opening hours, and contact of the Participating Branch/Outlet, and particulars of the contact person of the Merchant for our internal communication, is accurate, up-to-date, complete, legal, valid, and does not infringe any third party’s intellectual property rights;</li>
                                    <li className="static-web-styling ol-li3-li">We shall have the right to prescreen any Material uploaded to the Merchant System before they are published on our Channels. We shall have the right to reject, edit, remove, or request for removal or amendment of any Material that, at our sole discretion, is inappropriate or in breach of any provision of the <strong>Agreement</strong> or any applicable laws or regulations;</li>
                                    <li className="static-web-styling ol-li3-li">We reserve the right to change, reclaim, or remove a handle/username listed in Lulu HK at any time.</li>
                                    <li className="static-web-styling ol-li3-li">We may disclose or publish any Material which is not of a confidential nature provided by the Merchant for promoting the Merchant Services;</li>
                                    <li className="static-web-styling ol-li3-li">The intellectual property rights in any logo, design, graphic and/or any other related materials owned and provided by the Merchant to Lulu HK shall vest in the relevant Merchant;</li>
                                    <li className="static-web-styling ol-li3-li">Merchant shall be solely responsible for the provision of its own internal facilities, including but not limited to mobile devices and telecommunications facilities necessary for using our Merchant Services. We assume no responsibility for any malfunction or maintenance of such facilities;</li>
                                    <li className="static-web-styling ol-li3-li">Merchant shall fully comply with the requirements of the Personal Data (Privacy) Ordinance, Chapter 486 of the Laws of the Hong Kong and/or other applicable laws in the jurisdiction where the Participating Branch/Outlet operates, to properly protect the Personal Data, in any event, provided by us or collected through our Channels;</li>
                                    <li className="static-web-styling ol-li3-li">Merchant are fully responsible for any breach of any provision of the <strong>Agreement</strong> or any applicable laws or regulations by any of its Related Parties using our Merchant Services. In the event of breach of any provision or any dishonest act of the Merchant, including but not limited to, providing inaccurate information about the price and stock quantity, we shall have the right, at our sole discretion, to suspend or terminate our provision of any or all of the Merchant Services subscribed by the Merchant and/or Merchant’s access and use of our Merchant System, without any compensation and notice;</li>
                                    <li className="static-web-styling ol-li3-li">We shall have the right to modify, upgrade or remove part or all of the components of the Merchant System at any time, at our sole discretion, without any notice to the Merchant, and Merchant shall have no right to object to such changes. However, if we make changes to the Merchant System that will materially affect the normal use of the system, we will give reasonable notice of such changes to the Merchant;</li>
                                    <li className="static-web-styling ol-li3-li">Merchant shall promptly notify us of any error or defect in the Merchant System and provide any data or output that we request for troubleshooting;</li>
                                    <li className="static-web-styling ol-li3-li">Merchant agrees to use its best effort to display the promotional materials provided and requested by us at the Participating Branch(es)/Outlet(s);</li>
                                    <li className="static-web-styling ol-li3-li">Merchant shall indemnify and hold us harmless from any fees, taxes and duties which arise from Lulu HK’s provision of any service to the Merchant but is not based on Lulu HK’s income. Such fees, taxes and duties paid by the Merchant shall not be considered a part of or a deduction from the service payment to Lulu HK; and</li>
                                    <li className="static-web-styling ol-li3-li">Lulu HK and Merchant shall keep confidential and shall not disclose, or cause or permit others to disclose, to any individual or entity, any information treated as confidential by the other party, save and except for the circumstances (a) where being required by any regulatory, governmental or judicial authorities/bodies, or (b) where disclosure to its solicitors, auditors, insurers or accountants on a need-to-know basis is required, and such other party shall thereafter be under a duty of confidentiality.</li>
                                </ol>
                            </li>
                        </ol><br/>
                    </li>

                    <li className="static-web-styling ol-h2-li"><h2>License</h2>
                        <p>User grants us a royalty-free, non-exclusive, worldwide, perpetual, irrevocable right and license to use, publish, perform, display, reproduce, distribute, promote, download, modify, re-format, create derivative works of, transfer, sell, or otherwise commercially or non-commercially exploit in any manner, any Materials Users uploaded or posted on our Channels in any form and anywhere to carry out the purposes outlined in our <strong>Agreement</strong>.</p>
                    </li>
                    
                    <li className="static-web-styling ol-h2-li"><h2>Intellectual Property Rights</h2>
                        <p>All contents and elements of our Channels, including but not limited to the Materials, user interface, layout, designs, sound/audio formats, database, software and HTML, are the intellectual properties of Lulu HK or the Users (as the case may be) and are protected by copyright, trademark, patent and other applicable intellectual property laws. Users may download or copy such contents and elements for their own legal and non-commercial use or other uses expressly permitted in the <strong>Agreement</strong>. Any use of any of such contents and elements other than those permitted under our <strong>Agreement</strong>, is strictly prohibited to Users and we reserve all our rights of enforcement of all our intellectual property rights of such contents and elements.</p>
                        <p>For the avoidance of doubt, any purported consent of any third parties (including Merchants) to the use of the contents or any part of the <strong>Agreement</strong> shall not in any way exonerate the Users from the restrictions and prohibitions imposed under the <strong>Agreement</strong> in whatsoever manner.</p>
                        <p>Users agree that we are free to use, disclose, adopt and/or modify any information, including but not limited to any Materials, ideas, concepts, proposals, suggestions and comments, provided by Users to Lulu HK in connection with the use of our Channels and services without any remuneration to them. Users hereby agree to waive any rights and claims for any fees, royalties, charges and/or other remunerations in relation to our  use, disclosure, adoption and/or modification of such information.</p>
                    </li>
                    
                    <li className="static-web-styling ol-h2-li"><h2>Links to third-party Websites / Media Platforms / Applications</h2>
                        <p>Our Channels may provide links to third-party websites/media platforms/applications which are not owned or managed by us. They are provided solely for convenience to Users and as Internet navigation tools, and not in any way as our endorsement of the contents on such third-party websites/media platforms/applications. Unless otherwise stated on the Channels, we have no control over such third-party websites/media platforms/applications and are not responsible for their content, accuracy and security. Where and when a User decides to click on any link on the Channels which grants User access to a third-party website/media platform/application, he/she/it does so entirely at his/her/its own risk. We assume no responsibility or liability for any loss or damage of any kind in connection with Users’ use of such third-party websites/media platforms/applications. Further, any Material uploaded or posted on our Channels by the Users may be viewed by users of other websites/media platforms/applications linked to the Channels and we shall not be liable for any improper and/or illegal use of such Material by any user or third-party from the third-party websites/media platforms/applications.</p>
                    </li>
                    
                    <li className="static-web-styling ol-h2-li"><h2>User’s Dealings with Merchants</h2>
                        <p>Any User’s dealings with or participation in promotions by, Merchants on the Channels or through our Channels, including the delivery of and the payment for goods and services, and any other terms, conditions, warranties or representations associated with such dealings or promotions, are solely between User and the Merchant. We shall not be responsible or liable for any loss or damage arising out of or relating to any such dealings or promotions, or the accuracy of any Material posted by the Merchants.</p>
                    </li>
                    
                    <li className="static-web-styling ol-h2-li"><h2>Responsibility</h2>
                        <p>We may not monitor the Channels at all times but reserve the right to do so. In any event, we take no responsibility whatsoever for any Material that is not posted by us on the Channels or through the Channels. Users acknowledge and agree that they are fully responsible for the form, content and accuracy of any Material submitted by them to Lulu HK or that is otherwise posted by them on  the Channels or through the Channels. We do not warrant that any Material will be viewed by any specific number of Users or that it will be viewed by any specific User.</p>
                        <p>We shall not be responsible for the quality and legitimacy of the Products provided by the Merchants. We do not guarantee the accuracy of the information posted by any Merchant on our Channels. Should any dispute arise between Merchant and User, they should resolve such disputes between themselves and we shall not be responsible and held liable for such disputes.</p>
                        <p>We shall not in any way be considered as an agent of any User or Merchant with respect to any use of the Channels. We shall not be responsible in any way for any direct or indirect damage or loss, for whatsoever reason, made by any party using our Channels and services.</p>
                        <p><strong>Whilst we endeavor to provide a quality service to all Users, we do not warrant that (a) our Channels will operate error-free, or be free of viruses or other harmful mechanisms; (b) any defects will be corrected; or (c) the operation of our Channels will be uninterrupted, timely or secure. If Users’ use of our Channels or services result in the need for replacing equipment or data, we shall not be responsible for those costs and any loss or damage of whatever nature. Our Channels and their contents are provided on an "as is" basis without any warranties of any kind. To the fullest extent permitted by law, the company disclaims all warranties, including but not limited to, any warranty in respect of (a) merchantability; (b) fitness for a particular purpose; (c) non-infringement of third-party rights; (d) availability of featured Products; and (e) accuracy, reliability, completeness or timeliness of the Materials on our Channels. Users should independently assess and verify the accuracy, completeness and reliability of the Materials on our Channels and seek professional advice where necessary.</strong></p>
                    </li>
                    
                    <li className="static-web-styling ol-h2-li"><h2>Own Risk</h2>
                        <p><strong>All Users shall use our Channels and any other websites/media platforms/applications accessed through our Channels, entirely at their own risk</strong>. All Users shall be responsible for their own communications on the Channels and the consequences of their activities on the Channels. We do not represent or guarantee the accuracy or reliability of any Material uploaded or posted by any User or endorse any opinions expressed by the Uploader. Any reliance by Users on any Materials posted by other Users shall be at their own risk.</p>
                    </li>
                    
                    <li className="static-web-styling ol-h2-li"><h2>Indemnity</h2>
                        <p>All Users shall defend, indemnify, and hold harmless Lulu HK, its officers, directors, employees, agents, partners, representatives, shareholders, servants, attorneys, predecessors, successors and assigns from and against any claims, demands, costs, liabilities, losses, damages, and expenses (including but not limited to legal fees on a full indemnity basis) arising out of or relating to their use of our Channels, their use of our services provided via our Channels, or their breach of any terms of the <strong>Agreement</strong>, to the fullest extent permitted by applicable law. We will provide prompt written notice of any such claim, suit, action or proceeding to the relevant User.</p>
                    </li>
                    
                    <li className="static-web-styling ol-h2-li"><h2>Disclaimer</h2>
                        <p><strong>To the fullest extent permitted by applicable laws, Lulu HK, its officers, directors, employees, agents, partners, representatives, shareholders, servants, attorneys, predecessors, successors and assigns shall not be liable for any losses or damages (including but not limited to direct, indirect, incidental, special, exemplary, punitive or consequential damages) directly or indirectly suffered by any User whatsoever arising out of or relating to (a) any Users’ use of or inability to use our Channels and services, including but not limited to any inaccuracy, error, omission, interruption, deletion, delayed transmission or loss in any Materials uploaded on the Channels or through the Channels, inability to use due to negligence and disputes between any parties; (b) any Users’ use of or inability to use any Products provided by any Merchants; and/or (c) any policies or behavior of any Merchants.</strong></p>
                        <p><strong>Lulu HK is not in any way an agent of any User or Merchant.</strong></p>
                    </li>
                    
                    <li className="static-web-styling ol-h2-li"><h2>System Availability</h2>
                        <p>We do not fully guarantee uninterrupted, continuous or secure User’s access to the any Services via our Channels. Part of or the entire Channels may be unexpectedly unavailable for whatever duration and for whatsoever reasons including but not limited to system malfunctions and disruptions, cyberattacks, and other Force Majeure events for which we shall not be held responsible (Please refer to Section 18 headed “<strong>Force Majeure Events</strong>” for details). We reserve the right to take any part of or the entire Channels offline with or without notice for various reasons including but not limited to system maintenance or upgrading.</p>
                    </li>
                    
                    <li className="static-web-styling ol-h2-li"><h2>Termination of Account and Removal of Materials</h2>
                        <p>We reserve the right to expel any User and prevent his/her/its further access to our Channels and services either temporarily or permanently, by ways of suspending or terminating his/her/its membership account, restricting or terminating his/her/its access to our services, or blocking his/her/its Internet Protocol address, at any time without notice for whatsoever reason including but not limited to breaching our <strong>Agreement</strong>, providing inaccurate information or inappropriate Materials, and violating the laws and regulations. We also reserve the right to remove any Material that is abusive, illegal, disruptive or inappropriate, at any time without notice.</p>
                        <p>We shall have the sole and absolute discretion to decide whether the User or Material has breached any of the <strong>Agreement</strong> and whether further actions, including but not limited to taking legal actions against such User, have to be taken, and such decision shall be final. In case we have not exercised the right under this Clause, it shall not be deemed to be a waiver.</p>
                    </li>
                    
                    <li className="static-web-styling ol-h2-li"><h2>Limitation of Liability</h2>
                        <p>Without prejudice to the above and subject to the applicable laws, the aggregate liability of Lulu HK to any User for all claims arising out of or relating to his/her/its use of our Channels and services shall be limited to the fees received by Lulu HK in respect of the services giving rise to such claims.</p>
                    </li>
                    
                    <li className="static-web-styling ol-h2-li"><h2>Governing Law and Jurisdiction</h2>
                        <p>These <strong>Terms and Conditions</strong> shall be governed by and construed in accordance with the laws of Hong Kong, unless otherwise specified.</p>
                        <p>Any dispute, controversy or claim arising out of or relating to these <strong>Terms and Conditions</strong>, or the breach, termination or invalidity of these <strong>Terms and Conditions</strong> shall be settled by arbitration in accordance with the UNCITRAL Arbitration Rules as at present in force and as may be amended by the rest of this Clause:</p>
                        <ol className="static-web-styling ol-li2"><li className="static-web-styling ol-li2-li">The appointing authority shall be HKIAC; </li>
                            <li className="static-web-styling ol-li2-li">The place of arbitration shall be in Hong Kong at the HKIAC; </li>
                            <li className="static-web-styling ol-li2-li">There shall be only one arbitrator; and</li>
                            <li className="static-web-styling ol-li2-li">The language to be used in the arbitral proceedings shall be English. </li></ol><br/>
                        <p>In the event of any breach of these <strong>Terms and Conditions</strong> by a party, the other party shall be entitled to remedies in law and equity as determined by arbitration.</p>
                    </li>
                    
                    <li className="static-web-styling ol-h2-li"><h2>Force Majeure Events</h2>
                        <p>Without prejudice to any provision in the <strong>Agreement</strong> limiting liability, we are not liable for any delay or failure in performance of our obligations, directly or indirectly, caused by circumstances beyond our reasonable control. These Force Majeure events include but are not limited to Internet failures, computer equipment failures, telecommunication equipment failures, other equipment failures, electrical power failures, strikes, labor disputes, riots, insurrections, civil disturbances, shortages of labor or materials, fires, floods, storms, explosions, acts of God, war, governmental actions, orders of domestic or foreign courts or tribunals, non-performance of third parties, or loss of or fluctuations in heat, light, or air conditioning.</p>
                    </li>
                    
                    <li className="static-web-styling ol-h2-li"><h2>Entire Agreement</h2>
                        <p>The <strong>Agreement</strong> shall constitute the entire agreement between Lulu HK and Users relating to the use of our Channels and services, and shall supersede all previous discussions, agreements, negotiations and communications, whether written or verbal, relating to the subject matters hereof.</p>
                    </li>
                    
                    <li className="static-web-styling ol-h2-li"><h2>Severability</h2>
                        <p>The provisions contained in each clause of these <strong>Terms and Conditions</strong> shall be enforceable independently of each of the others and its validity shall not be affected if any of the others is invalid. In the event any provision or any part of a provision of these <strong>Terms and Conditions</strong> is determined to be or becomes illegal, invalid or unenforceable, the validity and enforceability of the remaining provisions or remaining part of the provision of these <strong>Terms and Conditions</strong> shall not be affected and, in lieu of such illegal, invalid, or unenforceable provision or part of a provision, there shall be added as part of these <strong>Terms and Conditions</strong> one or more provisions as similar in terms as may be legal, valid and enforceable under the applicable laws.</p>
                    </li>
                    
                    <li className="static-web-styling ol-h2-li"><h2>Rights of Third Parties</h2>
                        <p>No person other than the parties to these <strong>Terms and Conditions</strong> shall have any right to enforce or enjoy the benefit of any of the provision of these <strong>Terms and Conditions</strong>.</p>
                    </li>
                    
                    <li className="static-web-styling ol-h2-li"><h2>Order of Precedence</h2>
                        <p>In the event of any conflict between these <strong>Terms and Conditions</strong>, <strong>Privacy Policy</strong>, specific terms of use on any of our Channels, and terms and conditions of any service agreement(s), including but not limited to any Service Order and specific terms of use for any special events hosted by Lulu HK, unless otherwise specified, the descending order of precedence shall be:</p>
                        <ol className="static-web-styling ol-li2"><li className="static-web-styling ol-li2-li">terms and conditions of any service agreement(s); </li>
                            <li className="static-web-styling ol-li2-li"><strong>Privacy Policy</strong>;</li>
                            <li className="static-web-styling ol-li2-li">specific terms of use on any of the Channels; and</li>
                            <li className="static-web-styling ol-li2-li">these <strong>Terms and Conditions</strong>.</li></ol><br/>
                    </li>

                    <li className="static-web-styling ol-h2-li"><h2>Prevailing Language</h2>
                        <p>If there is any inconsistency or ambiguity between the English version and any version in other languages, the English version shall prevail.</p>
                    </li>
                    
                    <li className="static-web-styling ol-h2-li"><h2>Enquiries</h2>
                        <p>Should you have any enquiries about these <strong>Terms and Conditions</strong>, please contact our Customer Service Representative of the Company through the following ways:</p>
                        <address><strong>Lulu HK Technologies Limited</strong><br/>
                        Email Address: <a href = "mailto: contact@luluhk.com">contact@luluhk.com</a></address>
                    </li>
                </ol>
            </div>
        </div>

        <nav className="static-web-styling navbar navbar-expand navbar-light bg-light border-top border-dark fixed-bottom" id="bottomnav">
            <div className="static-web-styling collapse navbar-collapse">
                <ul className="static-web-styling navbar-nav mr-auto">
                    <li className="static-web-styling nav-item-left"><a className="static-web-styling nav-link active" href="/en/terms" onClick={()=>changeLanguage("en")}>EN</a></li>
                    <li className="static-web-styling nav-item-left"><a className="static-web-styling nav-link" href="/tc/terms" lang="zh-Hant" hrefLang="zh-Hant" onClick={()=>changeLanguage("zh_Hant")}>繁</a></li>
                    <li className="static-web-styling nav-item-left"><a className="static-web-styling nav-link" href="/sc/terms" lang="zh-Hans" hrefLang="zh-Hans" onClick={()=>changeLanguage("zh_Hans")}>简</a></li>
                </ul>
                <span className="static-web-styling copyright">&copy; 2024 Lulu HK Technologies Limited</span>
            </div>
        </nav>  
            </div>
        )
    }
}

export default TermsWebEn;
