import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import App from './App';
import reportWebVitals from './reportWebVitals';
import ReactGA from 'react-ga4';
import "./i18n";
import './index.css';

ReactGA.initialize('G-CL68T98HVD');

const sendToGoogleAnalytics = ({ name, delta, id }) => {
  ReactGA.event({
    category: "Web Vitals",
    action: name,
    value: Math.round(name === "CLS" ? delta * 1000 : delta),
    label: id,
    nonInteraction: true,
  });
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter future={{ v7_startTransition: true, v7_relativeSplatPath: true }}>
      <Suspense fallback={<div>Loading...</div>}>
        <App />
      </Suspense>
    </BrowserRouter>
  </React.StrictMode>,
);

reportWebVitals(sendToGoogleAnalytics);
